/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-11-04 09:09:48
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels, genVali } from '@/plugins/widget/c-form/rules';

export var models = genModels(function (genRules, ctx) {return [
  {
    kname: 'userPassword',
    itemProps: {
      label: '原密码：',
      rules: [
      genRules('require')] },


    component: {
      name: 'el-input',
      props: {
        placeholder: '请输入原密码',
        type: 'password' } } },



  {
    kname: 'newPwd',
    itemProps: {
      label: '新密码：',
      rules: [
      genRules('require'),
      genRules('pwd')] },


    component: {
      name: 'el-input',
      props: {
        placeholder: '请输入新密码',
        maxlength: 16,
        type: 'password' } } },



  {
    kname: 'checkPwd',
    itemProps: {
      label: '确认新密码：',
      rules: [
      genRules('require'),
      // genRules(v => v === ctx.form.newPassword, undefined, '两次密码输入不一致', 'blur')
      genVali(function (v) {return v === ctx.form.newPwd;}, '两次密码输入不一致', 'blur')] },


    component: {
      name: 'el-input',
      props: {
        placeholder: '请再次输入新密码',
        type: 'password' } } }];});






// form 默认值
export var formData = function formData() {return { list: [] };};